/* You can add global styles to this file, and also import other style files */
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');
// @import "./styles/feed-styles.scss";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
// @import "~css-star-rating/css/star-rating.css";
// @import "./assets/font-awesome.css";
// @use '~@angular/material' as mat;
@font-face {
    font-family: "Arimo-Regular";
    src: url("/assets/font-family/Arimo-Regular.ttf");
}

@font-face {
    font-family: "Barlow-Regular";
    src: url("/assets/font-family/Barlow-Regular.ttf");
}

@font-face {
    font-family: "Barlow-SemiBold";
    src: url("/assets/font-family/Barlow-SemiBold.ttf");
}

@font-face {
    font-family: "LaoUI";
    src: url("/assets/font-family/LaoUI.ttf");
}

@font-face {
    font-family: "Lao UI Bold";
    src: url("/assets/font-family/Lao UI Bold.ttf");
}

@font-face {
    font-family: "Arimo-Bold";
    src: url("/assets/font-family/Arimo-Bold.ttf");
}

@font-face {
    font-family: "Barlow-Medium";
    src: url("/assets/font-family/Barlow-Medium.ttf");
}
:host mat-error {
    color: red !important;
  }
.editor {
    font-family: 'Arial', sans-serif; 
  }
//font families
$headerHeight: 83px;
$marginBottomAboutUs: 100px;
$headerHeightResponsive: 154px;
$robotoRegular: "Arimo-Regular";
$robotoMedium: "Lao UI Bold";
$arimoBold: "Arimo-Bold";
$barlowRegular: "Barlow-Regular";
$barlowSemiBold : "Barlow-SemiBold";
$barlowMedium : "Barlow-Medium";
$background: #016ea9;
$popupBackgroundColor:#0c2159;    
$backgroundGradientColor: linear-gradient(#016ea9,
#042f7d);
$whiteGradientrColor:linear-gradient(#ffff,#FFF8F8);
$bordrGradientrColor:linear-gradient(45deg, rgb(169, 184, 243, 1), rgb(244, 196, 213, 1),rgb(253, 216, 203, 1)) 1;
$footerGradientColor:linear-gradient(); 
$lineGradientColor:linear-gradient(to right, #3e2e7f, #e176e2); 
$backgroundThemeColor: var(--background-theme-color);
$fontFamily: var(--language-font);
$badgeBackgroundColor: #ff0050;
$logoThemeColor:#9f277a;
* {
  font-family: $robotoRegular;
}

//  font size global variables
// $fontSizeNormal: 14px;
// $fontSizeMedium: 16px - 2px;
// $fontSize22: 22px - 2px;
// $fontSizeHeading: 24px - 2px;
// $fontSizePageHeading: 26px;
.font-small{
  font-size:12px;
}
.font-normal{
  font-size:14px;
}
.font-medium{
  font-size:16px;
}
.font-card-heading{
font-size:18px;
}
.font-sub-heading{
  font-size:20px;
}
.font-heading{
  font-size:22px;
}
.font-main-heading{
  font-size:26px;
}

.mat-drawer-content {
    // position: fixed !important;
    z-index: 1;
    display: block;
    // width: calc(100% - 430px);
    height: 100%;
}
// $fontSizeMedium: var(--fontSize14);
// $fontSize22: var(--fontSize20);
// $fontSizeHeading: var(--fontSize22);
// $fontSizePageHeading: var(--fontSize26);

$curoselHeightDesktop: 450px;
$curoselHeightMobile: 350px;
// $fontSizeCardHeading: 20px - 2px;

//border radius for cards 20px
$cardBorderRadius: 20px;

//box shadow global variable
$boxShadow: 5.4px 8.4px 20px 0 rgba(0,
0,
0,
0.29);
$cityInfoBoxShadow: 0px 0px 4px 0px rgba(0,
0,
0,
0.29);
//blue outline none for all div tags
div,
p,
span,
label {
    // line-height: 1.32;
    outline: none;
    border: 0;
}


/* Track */

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
    
    
   
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
    
    
}



/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #b30000;
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-size:14px;
  // overflow: hidden;
  background-color: #f3f3f3;
  cursor: default;
}

// ********** material properties
.material-icons {
  font-family: "Material Icons";
  // cursor: pointer;
  font-size: 24px;
  touch-action: unset !important;   
  color:  #3A067B;
  ;
}

.purple-mat-icon {
    color: #9f2578;
}

.back-arrow {
  position: absolute;
  left:4%;
  top: 10px;
  font-size: xx-large;
  cursor: pointer;
  color: #0c2159;
}

.blue-mat-icon {
    color: #0068b7;
}
star-rating > div{
    display:flex;
}
.eye-mat-icon {
    color: #0068b7;
}

.la-pacman {
    color: $background !important;
}

.fa {
    font: normal normal normal 17px/1 "FontAwesome";
}

.mat-accordion .mat-expansion-panel:last-of-type {
    margin-bottom: 2px;
}

.mat-expansion-panel-body {
    padding: 0px 24px 30px !important;
}

body {
    width: 100%;
    margin: 0;
    padding: 0;
    // overflow: hidden;
    background-color: #f3f3f3;
    cursor: default;
}

.spinner-loader {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.connectionDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 65px;
    margin-bottom: 100px;
}

.mat-ink-bar {
    visibility: visible;
    left: 86px;
    width: 84px;
}

// tooltip properties
.primary-tooltip {
  min-width: 100px !important;
  text-align: center !important;
}

.engagement-tooltip {
//   min-width: 170px !important;
  text-align: center !important;
}

.search-primary-tooltip {
  min-width: 50px !important;
  text-align: center !important;
}

.my-custom-tooltip {
  min-width: 200px !important;
  text-align: center !important;
}
.service-tooltip font-normal {
  min-width: 100px !important;
  text-align: center !important;
}

// radio button properties
.radio-group .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    // border-color: #0068b7;
    // border-color: #0c2159 ,#770398;
    border-color: linear-gradient( #350076  ,#770398 );
}

.radio-group .mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    // background-color: #0068b7;
    background-color:#350076;
}

.mat-radio-button {
    padding-left: 4px;
}

// date picker properties
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button,
.mat-datepicker-toggle {
    color: #0068b7;
}

// checkbox properties
::ng-deep .checkbox>.mat-checkbox-background {
    background-color: white !important;
}

::ng-deep .mat-checkbox .mat-checkbox-frame {
    border-color: #0068b7 !important;
    border-radius: 3px;
}

.mat-checkbox-checkmark-path {
    stroke: #0c2159 !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #ffffff;
    border: solid 2px #0068b7 !important;
}

.mat-raised-button {
    padding: 0 7px;
}

// mat form filed properties
.mat-form-field-subscript-wrapper {
    font-size: 75%;
    height: 20px;
    margin-top: 20px;
    top: calc(100% - 1.7916666667em);
}

.mat-form-field-wrapper {
    padding-bottom: 4px;
}
.mat-form-field-infix{
    line-height: 1.5;
}
.mat-error{
    line-height: 1.5;
}

//For managing alignment of DOB field of registration in IE
// .mat-form-field-flex{
//   display: flex !important;
//   align-items: center !important;
// }
.mat-input-element {
    line-height: 1.5;
    width: 90%;
   
    // height: 60px;
 // margin-bottom: 30px;
    
}

// .mat-form-field {
//   line-height: 17px !important;
// }
//  .cdk-overlay-connected-position-bounding-box {
//   top: 65px !important;
//   left: 937px !important;
//   height: 611px;
//   width: auto !important;
//   // width: 332.297px;
//   align-items: flex-start;
//   justify-content: flex-start;
// }
// .form .mat-form-field-appearance-outline .mat-form-field-outline {
//   color: #ffffff !important;
//   font-weight: lighter !important;
// }
// .form .mat-input-element {
//   caret-color: #ffffff;
// }
// .form-password .mat-form-field-appearance-outline .mat-form-field-outline {
//   color: #ffffff !important;
//   font-weight: lighter !important;
// }
// .form-password .mat-input-element {
//   caret-color: #ffffff;
// }
.mat-select-panel {
    max-height: 206px !important;
}

.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option {
    height: 42px !important;
}

.input-field .mat-select-arrow {
    color: #0068b7 !important;
}

.mat-menu-panel.image {
    min-width: 210px;
    max-width: 210px !important;
    border-radius: 25px !important;
    min-height: 59px !important;
    position: relative;
    top: 27px;
    right: 80px;
}

// # Shanu Jha - Removing the below style, that is causing issue with uneven adding in form items
// .ng-star-inserted{
    // margin-bottom: 25px !important;
// }
// .mat-menu-panel {
//     __<<ngThemingMigrationEscapedComment4>>__
//     __<<ngThemingMigrationEscapedComment5>>__
//     __<<ngThemingMigrationEscapedComment6>>__
//     // margin-left: 124px;
//     // border-radius: 4px;
//     // min-height: 64px;
//     overflow: unset !important;
//     min-width: unset !important;
//     margin-left: 120px !important;
//     84px
// }
// .cdk-overlay-connected-position-bounding-box > .cdk-overlay-pane > .mat-menu-content {
//   margin-left: 110px !important;
// }
// .cdk-overlay-pane {
//   right: 70.938px !important;
// }
// input type number
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

input:-internal-autofill-selected {
    background-color: #ffffff !important;
    background-image: none !important;
    color: #000000 !important;
}

.mat-dialog-container {
    display: inline !important;
}

// mat-list
.mat-list-base .mat-list-item .mat-line:nth-child(n + 2) {
  font-size: 14px;
  font-weight: 500 !important;
}

.mat-list-base .mat-list-item .mat-list-item-content {
    padding: 0 !important;
}

.basic-details .mat-list-base .mat-list-item .mat-line {
    display: block;
    box-sizing: border-box;
    white-space: nowrap;
    width: 215px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mat-list-base .mat-list-item .mat-list-text>*,
.mat-list-base .mat-list-option .mat-list-text>* {
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: inherit;
}

//NOTE update profile for IE / Safari
.mat-list-base .mat-list-item,
.mat-list-base .mat-list-option {
    width: auto;
}

// .cdk-overlay-connected-position-bounding-box {
//   width: auto !important;
// }
// mat-dialog
.mat-dialog-container {
    display: inline !important;
    padding: 0 !important;
    overflow: 0 !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #0096d7;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background: #c7dce5;
}

// mat-menu
// .mat-menu-content:not(:empty) {
   
// }

.mat-menu-content:not(:empty) {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    max-height: 500px;
}

.notification .mat-menu-panel {
    min-width: 480px;
}

// mat-slide
.mat-slide-toggle-label {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    height: inherit;
    cursor: pointer;
    justify-content: space-between;
    margin-left: 17px;
}

.mat-slide-toggle-bar {
    position: relative;
    display: flex;
    width: 36px;
    height: 14px;
    flex-shrink: 0;
    border-radius: 8px;
    right: 0px;
    order: 2;
}

.mat-slide-toggle-bar {
    margin-right: 32px;
}

.mat-badge-content {
    color: #fff;
    background: $badgeBackgroundColor;
}

// spinner properties
.mat-progress-spinner {
    width: 40px !important;
    height: 40px !important;
}

.mat-progress-spinner svg {
    width: 100% !important;
    height: 100% !important;
}

.mat-progress-spinner.mat-progress-spinner-indeterminate-animation[mode="indeterminate"] circle {
    stroke-width: 8%;
}

.popup-spinner .mat-progress-spinner circle,
.mat-spinner circle {
    stroke: #f2f4ff !important;
}

// mat-card
.mat-card-title {
    display: block;
    margin-bottom: 10px;
}

.mat-card-content[_ngcontent-ncy-c7],
.mat-card-subtitle[_ngcontent-ncy-c7] {
  font-size: 14px;
  margin-bottom: 10px !important;
}

// mat-drawer
.mat-drawer-inner-container {
    width: 100%;
    height: 100%;
    // overflow:unset !important;
}

.mat-drawer {
    overflow: hidden;
}

#container {
    padding: 0;
    .ng2-carouselamos-container {
        width: 100%;
        margin: auto;
        .ng2-carouselamos-wrapper {
            width: 100% !important;
            height: 450px;
            .ng2-carouselamos {
                height: 100%;
                // display: -webkit-box;
            }
        }
    }
    .slide-items {
        margin-right: 10px;
        img {
            width: 210px;
        }
    }
}

.cdk-overlay-pane {
    max-width: 100% !important;
    pointer-events: auto !important;
    display: flex;
    flex-direction: row-reverse;
    // right: 70.938px !important;
}

a {
    text-decoration: none;
}

//materialize carousel global
.carousel .indicators {
    display: none;
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
}

// .carousel .indicators .indicator-item {
//     display: inline-block;
//     position: relative;
//     cursor: pointer;
//     height: 8px;
//     width: 8px;
//     margin: 24px 4px;
//     background-color: rgba(255, 255, 255, 0.5);
//     -webkit-transition: background-color 0.3s;
//     transition: background-color 0.3s;
//     border-radius: 50%;
// }

// .carousel .indicators .indicator-item.active {
//     background-color: #042f7d;
//     height: 10px;
//     width: 10px;
// }

//chatbot style
.mat-fab .mat-button-wrapper {
    padding: 0 !important;
}

//NOTE  cross browser IE / Safari
.cdk-global-overlay-wrapper {
    justify-content: center !important;
    align-items: center !important;
}


@media (min-width: 1024px) {
     ::-webkit-scrollbar {
        width: 10px;
    }
    /* Track */
     ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgb(194, 194, 194);
        border-radius: 10px;
    }
    /* Handle */
     ::-webkit-scrollbar-thumb {
        background: #afafaf;
        border-radius: 10px;
    }
    /* Handle on hover */
     ::-webkit-scrollbar-thumb:hover {
        background: #afafaf;
        position: sticky;
    
        
    }
    .dialogbox-register .mat-dialog-container {
        display: inline !important;
        border-radius: 20px 20px 20px 20px !important;
    }
}

@media (max-width: 800px) {
    #container .ng2-carouselamos-container .ng2-carouselamos-wrapper {
        height: 300px;
        width: 100% !important;
    }
    .align-content {
        width: 300px;
        position: absolute;
        top: 35%;
        left: 14%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

//For IE
@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .mat-menu-panel {
        // background-color: red;
        margin: 460px 55px 0px 80px !important;
    }
}

@media (min-width: 250px) and (max-width: 600px) {
    .connectionLostImg {
        width: 100%;
        height: 100%;
    }
    .mat-menu-content:not(:empty) {
        padding-top: 0px !important;
        padding-bottom: 8px;
        max-height:400px;
        overflow-y: auto;
    }
    .mat-menu-panel.image {
        min-width: 112px;
        max-width: 210px !important;
        border-radius: 25px !important;
        min-height: 59px !important;
        position: relative;
        top: 14px;
        left: 68px;
    }
    .mat-menu-panel {
        overflow: unset !important;
        min-width: unset !important;
        margin-left: 84px !important;
    }
    .overlap-container {
        position: relative;
        top: 15px;
    }
    .slider-components {
        margin-top: 0px;
    }
}


.icon {
    width: unset !important;
}

.coming-soon-container{
    display: flex;
    justify-content: center;
    padding-bottom: 100px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

// Preventing Horizontal Scroll
html {
    overflow-x: hidden;
}